import React, { FC, ReactNode } from 'react'
import cx from 'clsx'
import NextLink from 'next/link'
import { getDynamicRoute, getStaticRoute } from '@/lib/routes'

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  link: any
  children?: ReactNode
  className?: string
}

const Link: FC<Props> = ({ link, children, className, ...rest }) => {
  const isLink = !!link.url
  const isStatic = getStaticRoute(link.page?.type)

  // External Link
  if (isLink) {
    return (
      <a
        href={link.url}
        target={!link.url.match('^mailto:|^tel:') ? '_blank' : null}
        rel="noopener noreferrer"
        className={
          link.isButton
            ? cx('btn', link.styles?.style, className, {
                'is-large': link.styles?.isLarge,
                'is-block': link.styles?.isBlock,
              })
            : null
        }
        {...rest}
      >
        {link.title || children}
      </a>
    )
    // Internal Page
  } else {
    const isDynamic = getDynamicRoute(link.page?.type)
    const isHome = link.page?.isHome
    const isShop = link.page?.isShop

    return (
      <NextLink
        href={
          isHome || isShop
            ? `/${isShop ? 'shop' : ''}`
            : isStatic !== false
            ? `/${isStatic}`
            : `/${isDynamic ? `${isDynamic}/` : ''}${link.page?.slug}`
        }
        scroll={false}
        className={
          link.isButton
            ? cx('btn', link.styles?.style, className, {
                'is-large': link.styles?.isLarge,
                'is-block': link.styles?.isBlock,
              })
            : null
        }
        {...rest}
      >
        {link.title || children}
      </NextLink>
    )
  }
}

export default Link
