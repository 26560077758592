/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import cx from 'clsx'
import CustomLink from '@/components/link'
import BlockContent from '@sanity/block-content-to-react'
import ImageElement from '../elements/image'

export const blockSerializers = {
  types: {
    block: (props) => {
      const { markDefs, style = 'normal' } = props.node

      // check if our block contains a button
      const hasButton = markDefs && markDefs.some((c) => c._type === 'link' && c.isButton === true)

      // build our mock header styles
      if (style === 'h1mock') {
        return <p className={cx('is-h1', { 'has-btn': hasButton })}>{props.children}</p>
      }

      if (style === 'h2mock') {
        return <p className={cx('is-h2', { 'has-btn': hasButton })}>{props.children}</p>
      }

      if (style === 'h3mock') {
        return <p className={cx('is-h3', { 'has-btn': hasButton })}>{props.children}</p>
      }

      if (style === 'h4mock') {
        return <p className={cx('is-h4', { 'has-btn': hasButton })}>{props.children}</p>
      }

      // go through our remaing, true header styles
      if (/^h\d/.test(style)) {
        return React.createElement(style, { className: hasButton ? 'has-btn' : null }, props.children)
      }

      // handle all other blocks with the default serializer
      return BlockContent.defaultSerializers.types.block(props)
    },
    image: () => {
      return <div>Image Placeholder</div>
    },
    photo: (props) => {
      const { node } = props
      return <ImageElement image={node} responsive className={'h-full'} priority={true} />
    },
    horizontalRule: () => <hr />,
  },
  marks: {
    link: ({ mark, children }) => {
      const { 0: title } = children

      return <CustomLink link={{ ...mark, title }} />
    },
  },
}
