import React, { FC } from 'react'
import { ConditionalWrapper } from '@/components/atoms/conditional-wrapper'
import BlockContent from '@/components/blocks/block-content'
import CustomLink from '@/components/link'
import { ImageAsset, LinkSelectorInterface, Section } from '@/types/site.interface'
import { Blocks } from '@/types/site.interface'
import Grid from '@ignition/library/components/atoms/grid'
import { GridColumns } from '@ignition/library/components/atoms/grid/interface'
import SectionElement from '../molecules/section'
import ImageElement from './image'

interface FeatureElementProps {
  _key?: string
  title?: string
  description?: Blocks
  icon?: ImageAsset
  link?: LinkSelectorInterface
}

const FeatureElement: FC<FeatureElementProps> = ({ title, description, icon, link }) => {
  return (
    <div className="pt-6">
      <ConditionalWrapper
        condition={link?.linkType !== 'none'}
        wrapper={(children) => {
          const linkProp = link.linkType === 'url' ? { url: link.url } : { page: link.entry }
          return <CustomLink link={linkProp}>{children}</CustomLink>
        }}
      >
        <div className="flow-root rounded-lg md:px-6 pb-8 h-full bg-feature-elevated-card">
          <div className="-mt-6">
            <div>
              {icon ? (
                <span className="inline-flex items-center justify-center rounded-md bg-primary p-3 shadow-lg">
                  <ImageElement image={icon} className="h-8 w-8 md:h-10 md:w-10 text-white relative" fill />
                </span>
              ) : (
                <div className="p-1.5 md:p-3" />
              )}
            </div>
            <h3 className=" mt-4 md:mt-8 text-base md:text-lg font-medium tracking-tight text-gray-900">{title}</h3>
            <div className="mt-5 text-sm md:text-base text-gray-500">
              <BlockContent className="px-2 leading-6 prose-base max-sm:break-words" blocks={description} />
            </div>
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  )
}

export interface FeatureGridProps {
  _key?: string
  features: FeatureElementProps[]
  columns: GridColumns
  section?: Section
}

export const FeatureGrid: FC<FeatureGridProps> = ({ features, columns, section }) => {
  return (
    <SectionElement section={section} contentClassName="text-center">
      <Grid columns={{ base: 2, md: 2, lg: columns }} gap={{ base: 2, md: 8 }}>
        {features?.map((feature) => (
          // eslint-disable-next-line react/jsx-key
          <FeatureElement key={feature._key} {...feature} />
        ))}
      </Grid>
    </SectionElement>
  )
}

export default FeatureGrid
