import React, { FC } from 'react'
import cx from 'clsx'
import { Blocks } from '@/types/site.interface'
import SanityBlockContent from '@sanity/block-content-to-react'
import { blockSerializers } from './block-serializers'

interface BlockContentProps {
  blocks: Blocks
  className?: string
}

const BlockContent: FC<BlockContentProps> = ({ blocks, className }) => {
  if (!blocks) return null

  return (
    <SanityBlockContent
      renderContainerOnSingleChild
      className={cx(className, 'block-content px-4 md:px-0 prose')}
      blocks={blocks}
      serializers={blockSerializers}
    />
  )
}

export default BlockContent
